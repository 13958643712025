import React from 'react'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import { rhythm } from '../utils/typography'

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2.5),
          padding: 15,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h5>
          We are currently under development. More coming soon...
        </h5>
      </div>
    )
  }
}

export default Bio
