import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Bio from '../components/Bio'
import '../components/style.css'
import { rhythm } from '../utils/typography';

const about = (props) => {

  const siteTitle = 'Contact Us'
  const siteDescription = 'Contact Full Meta Marketing about your new website, paid search or SEO project. We will discuss your needs and provide a free audit of your current site. Get started Today!'

  return (
    <Layout location={props.location} title={siteTitle}>
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      meta={[{ name: 'description', content: siteDescription }]}
      title={siteTitle}
    />
    <h5 style={{textAlign: 'center',}}>Submit the form below to contact us about your digital marketing project.</h5>
    <typography>
    <section id="contact" style={{marginBottom: '3rem',}} >
        <div className="inner" >
            <section style={{maxWidth: rhythm(12), margin: 'auto'}}>
                <form name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">

                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />

                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" required/>
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" required/>
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="6" required></textarea>
                    </div>
                    <div className="field">
                      <button type="submit" value="Send Message" className="btn-submit">Submit</button>
                    </div>
                       
                </form>
            </section>
        </div>
    </section>
    </typography>
    
    </Layout>
  )
}

export default about
